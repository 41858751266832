<template>
  <div class="login">
    <Modal v-if="showModal" @closeModal="closePopup()">
      <template v-slot:icon>
        <img
            src="@/assets/images/elements/something wrong.svg"
            alt="Something wrong"
        />
      </template>
      <template v-slot:content>
        <p class="modal-content" v-if="status_code == 423">
          {{ error_msg }}
        </p>
        <p class="modal-content" v-else>
          يرجى الرجوع لقسم المبيعات لإعادة تفعيل الحساب مرة اخرى , و يرجى التأكد
          من أسعار الشحن قبل بدأ العمل
        </p>
      </template>

      <template v-slot:second-btn>
        <button
            class="first-btn"
            @click.stop="(showModal = false), (showNumbersModal = true)"
        >
          اتصل بنا
        </button>
      </template>
    </Modal>
    <Modal v-if="showNumbersModal" @closeModal="closePopup()">
      <template v-slot:content>
        <h5 class="font-bold w-full text-center">ارقام الاستعلام</h5>
        <p class="modal-content modal-phone my-4">
          <feather-icon
              icon="PhoneIcon"
              svgClasses="text-primary h-5 w-5"
              class="mr-2"
          />
          {{ phone1 }}
        </p>
        <p class="modal-content modal-phone">
          <feather-icon
              icon="PhoneIcon"
              svgClasses="text-primary h-5 w-5"
              class="mr-2"
          />
          {{ phone2 }}
        </p>
      </template>
    </Modal>
    <div class="inner-login">
      <span class="welcome">{{ $t("welcomAgain") }}</span>
      <h1>{{ $t("loginToYourAccount") }}</h1>
      <vs-input
          name="email"
          icon-no-border
          icon="icon icon-user"
          icon-pack="feather"
          :label-placeholder="$t('mail')"
          v-model="user.email"
          class="w-full mt-0"
          v-validate="'required|email|min:3'"
      />
      <span style="font-size: 0.7rem" class="text-danger font-bold">{{
          errors.first("email")
        }}</span>

      <div class="inner-confirm-input">
        <svg
            @click="showPassword = !showPassword"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 22.172 19"
        >
          <path
              id="Icon_ionic-md-eye-off"
              data-name="Icon ionic-md-eye-off"
              d="M13.338,8.5a5.026,5.026,0,0,1,5.042,5,4.812,4.812,0,0,1-.361,1.831l2.944,2.919a11.828,11.828,0,0,0,3.459-4.75A11.94,11.94,0,0,0,9.316,6.7l2.177,2.162A4.985,4.985,0,0,1,13.338,8.5ZM3.259,5.772l2.3,2.281.465.46A11.772,11.772,0,0,0,2.25,13.5a11.958,11.958,0,0,0,15.5,6.66l.426.421L21.131,23.5l1.282-1.272L4.536,4.5ZM8.831,11.3l1.564,1.549a2.834,2.834,0,0,0-.079.648,3.008,3.008,0,0,0,3.023,3,2.844,2.844,0,0,0,.653-.079l1.564,1.549A5.016,5.016,0,0,1,8.3,13.49,4.942,4.942,0,0,1,8.831,11.3Zm4.344-.777,3.177,3.152.02-.158a3.008,3.008,0,0,0-3.023-3Z"
              transform="translate(-2.25 -4.5)"
              fill="#848484"
          />
        </svg>
        <vs-input
            :type="showPassword ? 'text' : 'password'"
            name="password"
            icon-no-border
            icon="icon icon-lock"
            icon-pack="feather"
            :label-placeholder="$t('password')"
            v-model="user.password"
            class="w-full mt-8"
            v-validate="'required'"
        />
        <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("password")
          }}</span>
      </div>

      <div class="flex flex-wrap justify-between my-5">
        <div class="flex">
          <vs-checkbox
              v-validate="'required'"
              name="checkbox"
              v-model="checkbox_remember_me"
              class="remember"
          >أوافق علي
          </vs-checkbox
          >
          <router-link
              to="/publictermsandconditions"
              class="remember remember2"
          ><u
              @click="
                googleEventTrack(
                  'الضغط على الشروط والاحكام',
                  'login',
                  'terms_and_conditions'
                )
              "
          >الشروط والأحكام</u
          ></router-link
          >
        </div>
        <router-link to="/confirm-mobile" class="remember"
        ><u
            @click="
              googleEventTrack(
                'الضغط على نسيت كلمة مرور',
                'login',
                'forget_password'
              )
            "
        >{{ $t("forgetPassword") }}</u
        ></router-link
        >
        <p
            style="font-size: 0.7rem"
            class="text-danger font-bold w-full"
            v-if="conditions == true && checkbox_remember_me == false"
        >
          يجب الموافقة علي الشروط والاحكام
        </p>
      </div>

      <div class="flex flex-wrap justify-between mb-3">
        <button
            class="btn-login w-full"
            :class="{ 'opacity-btn1': !validateForm }"
            @click="submit($event)"
            v-if="!load"
        >
          {{ $t("login") }}
        </button>
        <button
            class="btn-login w-full flex justify-center items-center"
            v-else
        >
          <loadSpiner></loadSpiner>
        </button>
      </div>
      <div class="text-center">
        <span class="text-danger text-sm" if="error_msg">{{ error_msg }}</span>
      </div>
      <div class="flex justify-center">
        <!-- here -->
        <a
            @click="googleEventTrack('الضغط على إشحن معانا', 'login', 'ship_with_us')"
            href="https://turbo.info/سجل-معنا-الآن-وتعرف-على-أسعار-تربو/"
            target="_blank"
            rel="nofollow"
        >ليس لديك حساب؟
          <span style="text-decoration: underline">إشحن معانا</span></a
        >
      </div>
      <footer
          class="the-footer flex-wrap justify-center"
          style="position: fixed; bottom: 0px"
      >
        <p>
          <span>COPYRIGHT &copy;</span>
          <span>{{ new Date().getFullYear() }} </span>
          <a href="https://turbo-eg.com" target="_blank" rel="nofollow">{{
              companyName
            }}</a>
          <span class="hidden sm:inline-block">, All rights Reserved</span>
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex";
import axios from "axios";
import loadSpiner from "@/views/components/table-components/SpinnerColor2";
import gTag from "@/mixins/gtag.js";
import Modal from "@/layouts/components/Modal.vue";

export default {
  mixins: [gTag],
  data() {
    return {
      checkbox_remember_me: false,
      user: {
        email: "",
        password: "",
      },
      load: false,
      conditions: false,
      showPassword: false,
      companyName: process.env.VUE_APP_CONFIGS_COMPANY_NAME,
      showModal: false,
      showNumbersModal: false,
    };
  },
  components: {
    loadSpiner,
    Modal,
  },
  computed: {
    ...mapGetters("auth", ["error_msg", "status_code"]),
    ...mapState("auth", ["deleted_account", "phone1", "phone2"]),
    validateForm() {
      return (
          !this.errors.any() &&
          this.user.email !== "" &&
          this.user.password !== "" &&
          this.checkbox_remember_me !== false
      );
    },
  },
  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("userData", ["changePassword"]),
    closePopup() {
      this.showModal = false;
      this.showNumbersModal = false;
    },
    async submit(event) {
      this.$validator.validateAll().then(async (result) => {
        if (this.validateForm) {
          this.load = true;
          event.preventDefault();
          await this.login(this.user);
          this.load = false;
          this.showModal = this.deleted_account || this.status_code == 423;
          let userData = JSON.parse(localStorage.getItem("userData"));
          if (userData) {
            const token = userData.token;
            axios.defaults.headers.common["Authorization"] = `Bearer ` + token;
            this.$router.push({name: "Home"});
          }
        } else {
          this.conditions = true;
        }
      });
      this.load = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_login.scss";
</style>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";

.remember {
  .checkbox_x {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.125rem;
    border: 0.0625rem solid $mainColor1 !important;
    margin-right: 0.5rem;
  }
}

.vs-con-input {
  .vs-input--input.hasIcon {
    height: 39px;
    padding-left: 2.2rem !important;
  }

  .vs-input--input.hasIcon:not(.icon-after-input) + .vs-input--placeholder {
    padding-left: 2.2rem !important;
  }
}

.isFocus {
  .vs-placeholder-label {
    margin-top: 5px;
  }
}
</style>
