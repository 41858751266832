<template>
  <div>
    <login-jwt></login-jwt>
  </div>
</template>


<script>
import LoginJwt from './LoginJWT.vue'

export default {
  components: {
    LoginJwt,
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 31.5625rem;

  .con-tab {
    padding-bottom: 0.875rem;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
