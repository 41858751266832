<template>
  <div class="parent-modal">
    <div class="modal">
      <i class="close-icon" @click="closeModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            id="Icon_ionic-md-close"
            data-name="Icon ionic-md-close"
            d="M23.523,9.123l-1.6-1.6-6.4,6.4-6.4-6.4-1.6,1.6,6.4,6.4-6.4,6.4,1.6,1.6,6.4-6.4,6.4,6.4,1.6-1.6-6.4-6.4Z"
            transform="translate(-7.523 -7.523)"
            fill="#212121"
          />
        </svg>
      </i>
      <slot name="icon"></slot>
      <slot name="content"></slot>
      <slot name="custom-message"></slot>
      <div class="buttons">
        <slot name="first-btn"></slot>
        <slot name="second-btn"></slot>
        <slot name="third-btn"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
.parent-modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 999999;
  background-color: #fff;
  right: 50%;
  transform: translate(50%, -50%);
  top: 50%;
  padding: 24px 50px;
  border-radius: 24px;
  box-shadow: 0px 8px 21px #0000001a;
  min-width: 400px;
  svg {
    margin-bottom: 12px;
  }
  .modal-content {
    color: #212121;
    font-size: 18px;
    line-height: 33px;
    margin-bottom: 12px;
    font-weight: bold;
    text-align: center;
    img {
      max-height: 273px;
      // max-height: 80vh;
      display: block;
      margin: auto;
      margin-bottom: 16px;
      border-radius: 4px;
    }
    .skeleton-loading {
      margin: auto;
      margin-bottom: 16px;
    }
    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
    }
  }
  .modal-phone {
    text-align: left;
    width: 100%;
    background: #21212114;
    border-radius: 4px;
    padding: 4px 16px;
    color: #212121;
    display: flex;
    align-items: center;
    font-weight: bold;
    svg {
      margin: 0px;
    }
  }
  .buttons {
    column-gap: 12px;
    display: flex;
  }
  button {
    border: none;
    background-color: $mainColor2;
    padding: 6px 24px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    border-radius: 6px;
    height: 38px;
    cursor: pointer;
    color: $mainColor1;
    .loader {
      border: 2px solid $mainColor1;
      border-radius: 50%;
      border-top: 2px solid #fff;
    }
  }
  .first-btn {
    background-color: $mainColor1;
    color: #fff;
  }
  .third-btn {
    background-color: #fff;
    border: solid 1px $mainColor1;
  }
  .close-icon {
    width: 100%;
    text-align: right;
    margin-left: 48px;
    cursor: pointer;
    height: 16px;
  }
}
</style>
