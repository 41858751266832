export default {
  methods: {
    googleEventTrack(actionName, catName, labelName) {
      gtag("event", actionName, {
        event_category: catName,
        event_label: labelName,
      });
    },
  },
};
